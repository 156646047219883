<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn x-large="" text v-on="on" dark>
          <v-icon class="hidden-sm-and-down">account_circle</v-icon>
          <h3 class="mx-4">{{ $store.state.user.userName }}</h3>
          <!-- <h3 class="mx-4">أمير هيثم عبدالأمير</h3> -->
          <v-icon class="hidden-sm-and-down">keyboard_arrow_down</v-icon>
        </v-btn>
      </template>

      <v-list dark>
        <v-list-item>
          <!-- <v-list-item-title ><strong>{{$store.state.user.userData.office.name }}</strong></v-list-item-title> -->
          <v-list-item-title
            ><strong>{{
              $store.state.user.user.jobTitle == null
                ? "Admin"
                : $store.state.user.user.jobTitle
            }}</strong></v-list-item-title
          >
        </v-list-item>

        <v-divider />

        <v-list-item>
          <v-list-item-title
            ><strong> {{ $t("smh-alalwan") }}: </strong></v-list-item-title
          >
          <themeChanger />
          <!-- <v-list-item-icon>
                    <v-icon class="red--text">power_settings_new</v-icon>
                </v-list-item-icon> -->
        </v-list-item>
        <v-divider />

        <v-list-item>
          <v-list-item-title
            ><strong>
              {{ $t("tghyyr-klmh-almrwr") }}:
            </strong></v-list-item-title
          >
          <v-tooltip bottom="">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="openChangePasswordDialog($store.state.user)"
                v-on="on"
                icon
              >
                <v-icon color=""> lock_reset </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("tghyyr-klmh-almrwr") }}
            </span>
          </v-tooltip>
        </v-list-item>

        <v-divider />

        <v-list-item @click="logout()">
          <v-list-item-title class="red--text"
            ><strong>
                {{ $t('tsjyl-khrwj') }}
                </strong></v-list-item-title
          >
          <v-list-item-icon>
            <v-icon class="red--text">power_settings_new</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <ChangePasswordDialog />
  </div>
</template>

<script>
export default {
  components: {
    themeChanger: () => import("./themeChanger"),
    ChangePasswordDialog: () =>
      import("../../views/users/shared/changePasswordDialog"),
  },

  methods: {
    logout() {
      sessionStorage.removeItem("token");
      this.$router.push("/login");
    },

    openChangePasswordDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setChangePasswordDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },

  created() {
    console.log(this.$store.state.user.user);
  },

  // created() {
  //     this.$eventBus.$on('user', (data) => {
  //         this.user = data.user
  //         // console.log(data.user.name)
  //     });
  //     this.user = JSON.parse(sessionStorage.getItem('user'))
  //     // console.log(this.user)
  // },
};
</script>
